<template>
	<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M40.0003 78.3333C18.8626 78.3333 1.66699 61.1368 1.66699 40C1.66699 18.8631 18.8626 1.66663 40.0003 1.66663C61.138 1.66663 78.3337 18.8631 78.3337 40C78.3337 61.1368 61.138 78.3333 40.0003 78.3333Z" fill="#72C472"/>
	<path d="M33.334 58.0476L15.2871 40L20.0006 35.2856L33.334 48.6189L60.0006 21.9523L64.7142 26.6666L33.334 58.0476Z" fill="white"/>
	</svg>
</template>

<script>
export default {
  name: 'Check',
}
</script>
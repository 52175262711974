<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M39.9997 76.6667C60.2501 76.6667 76.6663 60.2505 76.6663 40C76.6663 19.7496 60.2501 3.33337 39.9997 3.33337C19.7492 3.33337 3.33301 19.7496 3.33301 40C3.33301 60.2505 19.7492 76.6667 39.9997 76.6667Z" fill="url(#paint0_linear)"/>
	<path d="M24.9993 46.6667C29.6017 46.6667 33.3327 42.9357 33.3327 38.3333C33.3327 33.731 29.6017 30 24.9993 30C20.397 30 16.666 33.731 16.666 38.3333C16.666 42.9357 20.397 46.6667 24.9993 46.6667Z" fill="white"/>
	<path d="M54.9994 46.6667C59.6017 46.6667 63.3327 42.9357 63.3327 38.3333C63.3327 33.731 59.6017 30 54.9994 30C50.397 30 46.666 33.731 46.666 38.3333C46.666 42.9357 50.397 46.6667 54.9994 46.6667Z" fill="white"/>
	<path d="M39.9993 73.3334C35.4043 73.3334 31.666 67.725 31.666 60.8334C31.666 53.9417 35.4043 48.3334 39.9993 48.3334C44.5943 48.3334 48.3327 53.9417 48.3327 60.8334C48.3327 67.725 44.5943 73.3334 39.9993 73.3334Z" fill="#AE453E"/>
	<path d="M40.0003 73.3334C43.6583 73.3334 46.6236 71.0948 46.6236 68.3334C46.6236 65.572 43.6583 63.3334 40.0003 63.3334C36.3423 63.3334 33.377 65.572 33.377 68.3334C33.377 71.0948 36.3423 73.3334 40.0003 73.3334Z" fill="#FA645A"/>
	<path d="M16.8182 48.155C15.9984 47.2411 14.8642 46.6696 13.6418 46.5546C12.4195 46.4395 11.1986 46.7893 10.2226 47.5342C9.24663 48.2792 8.58718 49.3645 8.37571 50.5739C8.16423 51.7834 8.41626 53.0281 9.08156 54.06C10.1811 56.625 10.9922 59.3042 11.4999 62.0483C12.2703 66.2409 12.3267 70.5336 11.6666 74.745C11.6297 74.9833 11.6449 75.2267 11.7112 75.4585C11.7776 75.6903 11.8934 75.9049 12.0507 76.0876C12.2081 76.2703 12.4032 76.4167 12.6226 76.5167C12.842 76.6166 13.0805 76.6678 13.3216 76.6667H26.6549C26.8762 76.6667 27.0952 76.6227 27.2993 76.5371C27.5034 76.4516 27.6884 76.3263 27.8435 76.1685C27.9987 76.0108 28.1208 75.8237 28.2029 75.6182C28.285 75.4127 28.3253 75.1929 28.3216 74.9717C28.2271 69.4157 26.9397 63.945 24.5466 58.93C22.6192 54.9147 20.0036 51.268 16.8182 48.155Z" fill="#E2AC4B"/>
	<path d="M70.1763 47.8901C69.2192 47.003 67.951 46.5291 66.6467 46.5712C65.3424 46.6133 64.1074 47.1681 63.2096 48.1151C60.008 51.2384 57.3788 54.8988 55.4413 58.9301C53.0482 63.9451 51.7608 69.4158 51.6663 74.9718C51.6625 75.193 51.7028 75.4128 51.7849 75.6183C51.867 75.8238 51.9891 76.0108 52.1443 76.1686C52.2994 76.3264 52.4844 76.4517 52.6885 76.5372C52.8926 76.6228 53.1116 76.6668 53.3329 76.6668H66.6663C66.9067 76.6667 67.1442 76.6146 67.3626 76.5141C67.581 76.4137 67.775 76.2671 67.9315 76.0846C68.0879 75.902 68.203 75.6878 68.2688 75.4566C68.3347 75.2254 68.3497 74.9827 68.3129 74.7451C67.2156 67.7434 68.1144 60.5742 70.9063 54.0601C71.5085 53.1032 71.7646 51.9688 71.6317 50.8461C71.4989 49.7233 70.9852 48.68 70.1763 47.8901Z" fill="#E2AC4B"/>
	<defs>
	<linearGradient id="paint0_linear" x1="39.9997" y1="76.6667" x2="39.9997" y2="3.33337" gradientUnits="userSpaceOnUse">
	<stop offset="0.464" stop-color="#FFD764"/>
	<stop offset="0.755" stop-color="#458BC4"/>
	</linearGradient>
	</defs>
	</svg>
</template>

<script>
export default {
	name: 'Scream',
}
</script>

<template>
	<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M17.0833 0.416687H3.33333C2.8731 0.416687 2.5 0.789783 2.5 1.25002V18.75C2.5 19.2103 2.8731 19.5834 3.33333 19.5834H17.0833C17.5436 19.5834 17.9167 19.2103 17.9167 18.75V1.25002C17.9167 0.789783 17.5436 0.416687 17.0833 0.416687Z" fill="#8AA5DB"/>
	<path d="M15.4163 2.91669H4.99967C4.76937 2.91669 4.58301 3.10325 4.58301 3.33335V5.83335C4.58301 6.06346 4.76937 6.25002 4.99967 6.25002H15.4163C15.6466 6.25002 15.833 6.06346 15.833 5.83335V3.33335C15.833 3.10325 15.6466 2.91669 15.4163 2.91669Z" fill="white"/>
	<path d="M7.08301 7.91669H4.99967C4.76937 7.91669 4.58301 8.10325 4.58301 8.33335V10C4.58301 10.2301 4.76937 10.4167 4.99967 10.4167H7.08301C7.31331 10.4167 7.49967 10.2301 7.49967 10V8.33335C7.49967 8.10325 7.31331 7.91669 7.08301 7.91669Z" fill="#395BB6"/>
	<path d="M11.25 7.91669H9.16667C8.93636 7.91669 8.75 8.10325 8.75 8.33335V10C8.75 10.2301 8.93636 10.4167 9.16667 10.4167H11.25C11.4803 10.4167 11.6667 10.2301 11.6667 10V8.33335C11.6667 8.10325 11.4803 7.91669 11.25 7.91669Z" fill="#395BB6"/>
	<path d="M15.417 7.91669H13.3337C13.1034 7.91669 12.917 8.10325 12.917 8.33335V10C12.917 10.2301 13.1034 10.4167 13.3337 10.4167H15.417C15.6473 10.4167 15.8337 10.2301 15.8337 10V8.33335C15.8337 8.10325 15.6473 7.91669 15.417 7.91669Z" fill="#395BB6"/>
	<path d="M7.08301 11.25H4.99967C4.76937 11.25 4.58301 11.4366 4.58301 11.6667V13.3333C4.58301 13.5634 4.76937 13.75 4.99967 13.75H7.08301C7.31331 13.75 7.49967 13.5634 7.49967 13.3333V11.6667C7.49967 11.4366 7.31331 11.25 7.08301 11.25Z" fill="#395BB6"/>
	<path d="M11.25 11.25H9.16667C8.93636 11.25 8.75 11.4366 8.75 11.6667V13.3333C8.75 13.5634 8.93636 13.75 9.16667 13.75H11.25C11.4803 13.75 11.6667 13.5634 11.6667 13.3333V11.6667C11.6667 11.4366 11.4803 11.25 11.25 11.25Z" fill="#395BB6"/>
	<path d="M15.417 11.25H13.3337C13.1034 11.25 12.917 11.4366 12.917 11.6667V16.6667C12.917 16.8968 13.1034 17.0833 13.3337 17.0833H15.417C15.6473 17.0833 15.8337 16.8968 15.8337 16.6667V11.6667C15.8337 11.4366 15.6473 11.25 15.417 11.25Z" fill="#FFCA33"/>
	<path d="M7.08301 14.5834H4.99967C4.76937 14.5834 4.58301 14.7699 4.58301 15V16.6667C4.58301 16.8968 4.76937 17.0834 4.99967 17.0834H7.08301C7.31331 17.0834 7.49967 16.8968 7.49967 16.6667V15C7.49967 14.7699 7.31331 14.5834 7.08301 14.5834Z" fill="#395BB6"/>
	<path d="M11.25 14.5834H9.16667C8.93636 14.5834 8.75 14.7699 8.75 15V16.6667C8.75 16.8968 8.93636 17.0834 9.16667 17.0834H11.25C11.4803 17.0834 11.6667 16.8968 11.6667 16.6667V15C11.6667 14.7699 11.4803 14.5834 11.25 14.5834Z" fill="#395BB6"/>
	</svg>
</template>

<script>
export default {
	name: 'Calculator',
}
</script>

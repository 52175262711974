<template>
  <div>
    <router-link
      v-if="to"
      :class="btnClass"
      :to="{ name: to }"
    >
      <slot></slot>
    </router-link>
    <button
      :class="btnClass"
      :type="[ submit ? 'submit': 'button' ]"
      v-else
    >
    <slot></slot>
  </button>
  </div>
</template>

<style lang="scss">
  [class^="exp-btn-"],
  [class*="exp-btn-"] {
    padding: 15px 24px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
    line-height: 20px;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    &:focus {
      outline: none;
    }
    &.has-icon {
      padding-left: 45px;
      position: relative;
    }
  }

  .exp-btn-icon {
    width: 20px;
    display: inline-block;
    position: absolute;
    left: 20px;
    top: 14px;
    padding: 0;
  }

  .exp-btn-primary {
    background-color: $green;
    color: white;
    border: 2px solid rgba(0, 0, 0, 0.1);
    &:hover,
    &:focus {
      color: white;
      transition: all 0.3s;
      background-color: darken($green, 10);
    }
    &:focus {
      border-color: rgba(0, 0, 0, 0.3);
    }
    &:active {
      color: white;
      transition: all 0.3s;
      border-color: $green;
    }
    &:disabled {
      cursor: default;
      background-color: lighten($green, 10);
    }
  }

  .exp-btn-inverted {
    background-color: white;
    border: 2px solid $grey;
    color: $text-color;
    &:hover {
      transition: all 0.3s;
      background-color: $grey;
    }
    &:focus {
      transition: all 0.3s;
      border-color: $blue;
    }
    &:active {
      transition: all 0.3s;
      border-color: white;
    }
    &:disabled {
      cursor: default;
      color: lighten($text-color, 10);
      background-color: $grey;
      border-color: rgba(0, 0, 0, 0.1);
    }
  }

  .exp-btn-white {
    background-color: white;
    border: 2px solid lighten($grey, 7);
    color: $text-color;
    &:focus {
      transition: all 0.3s;
      border-color: $blue;
      outline: none;
    }
    &:active {
      transition: all 0.3s;
      background-color: lighten($blue, 30);
    }
    &:disabled {
      cursor: default;
      color: lighten($text-color, 10);
    }
  }

</style>

<script>
  export default {
    name: 'Button',
    props: {
      type: {
        /* possible value : primary, inverted, white */
        type: String,
        required: true,
        default: 'primary',
      },
      icon: {
        type: Boolean,
        required: false
      },
      additionalClass: {
        type: String,
        required: false,
      },
      submit: {
        type: Boolean,
        required: false,
      },
      to: {
        type: String,
        required: false,
      }
    },

    computed: {
      btnClass: function() {
        return [`exp-btn-${this.type}`, this.additionalClass ? this.additionalClass: '', this.icon ? 'has-icon': ''];
      }
    },
  }
</script>
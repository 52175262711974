<template>
  <div>
    <Popup :open="popupOpen" :closeFunction="closeFunction">
      <div v-if="!submitOk && !submitError">
        <div class="exp-popup__title">
          &Eacute;crivez-nous, nous vous répondons rapidement !
        </div>
        <form netlify v-on:submit.prevent="handleSubmit" name="Contact">
          <label for="expContactName" class="exp-label">
            Nom et prénom
          </label>
          <input
            :id="`${popupId}-expContactName`"
            type="text"
            class="exp-input"
            placeholder="Entrez votre nom et prénom..."
            name="name"
            @blur="!inputHasErrors('name')"
            v-model="form.name"
            :class="{'inError': errors.name}"
          >
          <div class="exp-form__error-message" :class="{'show': errors.name}" data-input="expContactName">
            Ce champ est obligatoire.
          </div>
          <label for="expContactMail" class="exp-label">
            Email
          </label>
          <input
            :id="`${popupId}-expContactMail`"
            class="exp-input"
            placeholder="Entrez votre adresse mail..."
            name="email"
            type="email"
            @blur="!inputHasErrors('email')"
            v-model="form.email"
            :class="{'inError': errors.email}"
          >
          <div class="exp-form__error-message" :class="{'show': errors.email}" data-input="expContactMail">
            Ce champ est obligatoire.
          </div>
          <label for="expContactNum" class="exp-label">
            Téléphone
          </label>
          <input
            :id="`${popupId}-expContactNum`"
            type="tel"
            class="exp-input"
            placeholder="Entrez votre numéro..."
            name="phone"
            @blur="!inputHasErrors('phone')"
            v-model="form.phone"
            :class="{'inError': errors.phone}"
          >
          <div class="exp-form__error-message" :class="{'show': errors.phone}" data-input="expContactNum">
            Ce champ est obligatoire.
          </div>
          <label for="expContactMessage" class="exp-label">
            Message
          </label>
          <textarea
            rows="1"
            name="message"
            :id="`${popupId}-expContactMessage`"
            class="exp-input"
            placeholder="Ecrivez votre message ici..."
            @blur="!inputHasErrors('message')"
            v-model="form.message"
            :class="{'inError': errors.message}"
          >
          </textarea>
          <div class="exp-form__error-message" :class="{'show': errors.message}" data-input="expContactMessage">
            Ce champ est obligatoire.
          </div>
          <div class="exp-align-right">
            <ExpBtn :submit="true" type="primary" class="exp-margin-top-30">
              Envoyer
            </ExpBtn>
          </div>
        </form>
      </div>

      <div class="exp-align-center" v-if="submitOk">
        <div class="exp-popup__icon">
          <Check />
        </div>
        <div class="exp-popup__title exp-margin-top-30">
          Message envoyé avec succès !
        </div>
      </div>

      <div class="exp-align-center" v-if="submitError">
        <div class="exp-popup__icon">
          <Scream />
        </div>
        <div class="exp-popup__title exp-margin-top-30">
          Oups ! Une erreur est survenue…<br>
          Réessayez ultérieurement.
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import ExpBtn from '../components/Button.vue';
import Check from '../assets/svg/check.vue';
import Popup from '../components/Popup.vue';
import Scream from '../assets/svg/scream.vue';
import axios from 'axios';

  export default {
    name: 'PopupContact',

    components: {
      Check,
      ExpBtn,
      Popup,
      Scream,
    },

    props: {
      popupOpen: {
        type: Boolean,
        required: true,
      },
      closeFunction: {
        type: Function,
        required: true,
      },
      popupId: {
        type: String,
        required: true,
      }
    },

    watch: {
      popupOpen() {
        if (this.popupOpen) {
          document.getElementsByTagName('body')[0].classList.add('exp-overflow-hidden');
        } else {
          this.submitError = false;
          this.submitOk = false;
          document.getElementsByTagName('body')[0].classList.remove('exp-overflow-hidden');
        }
      },

      formMessage() {
        const input = document.getElementById(`${this.popupId}-expContactMessage`);
        const outerHeight = parseInt(window.getComputedStyle(input).height, 10);
        const diff = outerHeight - input.clientHeight;
        input.style.height = 0;
        input.style.height = Math.max(input.scrollHeight, input.scrollHeight + diff) + 'px';
      },
    },

    data() {
      return {
        form: {
          name: undefined,
          email: undefined,
          phone: undefined,
          message: undefined,
        },
        errors: {
          name: false,
          email: false,
          phone: false,
          message: false,
        },
        submitOk: false,
        submitError: false,
      };
    },

    computed: {
      formMessage() {
        return this.form.message;
      }
    },

    methods: {
      encode(data) {
        return Object.keys(data)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join("&");
      },

      inputHasErrors(vmodel) {
        return this.errors[vmodel] = !this.form[`${vmodel}`];
      },

      formHasErrors() {
        return Object.keys(this.errors)
          .map((key) => {
            return this.inputHasErrors(key);
          })
          .filter((value) => value)
          .length >= 1
        ;
      },

      handleSubmit() {
        if (!this.formHasErrors()) {
          const axiosConfig = {
            header: { "Content-Type": "application/x-www-form-urlencoded" }
          };

          let _this = this;

          console.log(this.encode({
                "form-name": "Contact",
                ...this.form,
              }));

          axios
            .post(
              "/",
              this.encode({
                "form-name": "Contact",
                ...this.form,
              }),
              axiosConfig
            )
            .then(function () {
              _this.submitOk = true;
            })
            .catch(function () {
              _this.submitError = true;
            })
          ;
        }
      }
    },
  }
</script>

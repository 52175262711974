<template>
  <div :class="['exp-popup', open ? 'open' : '']">
    <div class="exp-popup__container">
      <Close @click.native="closeFunction" class="exp-popup__close"/>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
  .exp-popup {
    position: fixed;
    top: 77px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    @media (min-width: $breakpoint-large) {
      top: 114px;
    }
    &.open {
      visibility: visible;
      z-index: 2;
      opacity: 1;
      transition: opacity 0.3s;
    }
    &__container {
      background-color: white;
      display: block;
      margin: 60px auto;
      width: 90%;
      max-width: 600px;
      padding: 40px 30px;
      border-radius: 16px;
      position: relative;
      @media (min-width: $breakpoint-large) {
        padding: 50px 100px;
        width: 90%;
      }
    }
    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
    }
    &__close {
      right: 14px;
      top: 14px;
      fill: lighten($blue, 20);
      width: 24px;
      position: absolute;
      cursor: pointer;
      transition: fill 0.3s;
      @media (min-width: $breakpoint-large) {
        right: 24px;
        top: 24px;
      }
      &:hover {
        fill: lighten($blue, 10);
        transition: fill 0.3s;
      }
    }
    &__icon {
      width: 80px;
      display: inline-block;
    }
  }
</style>

<script>
import Close from '../assets/svg/close.vue';

  export default {
    name: 'Popup',

    components: {
      Close,
    },

    props: {
      open: {
        type: Boolean,
        required: true,
      },
      closeFunction: {
        type: Function,
        required: true,
      },
    },
  }
</script>
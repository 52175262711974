<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.501487 23.4985C0.822686 23.8196 1.25827 24 1.71244 24C2.16662 24 2.6022 23.8196 2.9234 23.4985L11.9893 14.4326L21.0552 23.4985C21.3782 23.8105 21.8109 23.9832 22.26 23.9793C22.7091 23.9754 23.1387 23.7952 23.4562 23.4776C23.7738 23.1601 23.9539 22.7305 23.9578 22.2814C23.9617 21.8323 23.7891 21.3996 23.4771 21.0766L14.4112 12.0107L23.4771 2.94481C23.6407 2.78681 23.7712 2.59781 23.8609 2.38884C23.9507 2.17987 23.998 1.95512 23.9999 1.72769C24.0019 1.50027 23.9586 1.27472 23.8725 1.06423C23.7863 0.853728 23.6591 0.66249 23.4983 0.50167C23.3375 0.34085 23.1463 0.213668 22.9358 0.127547C22.7253 0.0414254 22.4997 -0.0019116 22.2723 6.467e-05C22.0449 0.00204094 21.8201 0.049291 21.6112 0.139058C21.4022 0.228824 21.2132 0.359309 21.0552 0.5229L11.9893 9.5888L2.9234 0.5229C2.60036 0.210898 2.1677 0.0382569 1.71861 0.0421594C1.26951 0.0460619 0.83992 0.226196 0.522352 0.543765C0.204783 0.861333 0.0246483 1.29093 0.0207458 1.74002C0.0168433 2.18911 0.189485 2.62177 0.501487 2.94481L9.56738 12.0107L0.501487 21.0766C0.180385 21.3978 0 21.8334 0 22.2876C0 22.7417 0.180385 23.1773 0.501487 23.4985Z"/>
  </svg>
</template>

<script>
  export default {
    name: 'Close',
  }
</script>
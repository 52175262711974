<template>
	<div :class="['exp-container', this.small ? 'exp-container--small': '']">
		<slot></slot>
	</div>
</template>

<style scoped lang="scss">
  .exp-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: $breakpoint-small) {
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
    }
    @media (min-width: $breakpoint-medium) {
      max-width: 720px;
    }
    @media (min-width: $breakpoint-large) {
      max-width: 960px;
    }
    @media (min-width: $breakpoint-extra-large) {
      max-width: 1140px;
    }
    &--small {
      @media (min-width: $breakpoint-large) {
        max-width: 945px;
      }
    }
  }
</style>

<script>
	export default {
		name: 'Container',

    props: {
      small: {
        type: Boolean,
        required: false,
        default: false,
      }
    }
	}
</script>
<template>
    <div class="exp-navbar">

      <PopupContact :popupOpen="popupOpen" :closeFunction="popupTrigger" popupId="navbar"/>

      <Container class="exp-clearfix">
        <MobileMenu class="exp-navbar-mobile__open" v-on:click.native="openMobileMenu"/>
        <div class="exp-navbar__logo">
          <router-link :to="{ name: 'landing' }" class="exp-navbar__logo__link">
            <Logo style="height: 100%"/>
          </router-link>
        </div>
        <ul class="exp-navbar__menu">
          <li class="exp-navbar__menu__item">
            <router-link :to="{ name: 'landing' }" :class="['exp-navbar__menu__link', landingActive ? 'active-link': '']">
              à propos
            </router-link>
          </li>
          <li class="exp-navbar__menu__item" @click.prevent.stop="popupTrigger">
            <button :class="['exp-navbar__menu__link', contactActive ? 'active-link': '']">
              Contact
            </button>
          </li>
          <li class="exp-navbar__menu__item">
            <a href="https://isuiteexpansae.coaxis.com/isuiteexpert/?CNX=CNX97&CAB=0000" target="_blank" rel="noopener" class="exp-navbar__menu__link">
              Connexion
            </a>
          </li>
          <li class="exp-navbar__menu__item">
            <a href="tel:0688859296">
              <ExpBtn type="white" :icon='true'>
                <Calculator class="exp-btn-icon"/>
                06 88 85 92 96
              </ExpBtn>
            </a>
          </li>
        </ul>
      </Container>
      <ul :class="['exp-navbar-mobile__menu exp-clearfix', this.mobileMenuOpen ? 'menuOpen': '']">
        <Close class="exp-navbar-mobile__close" v-on:click.native="closeMobileMenu()"/>
        <li class="exp-navbar-mobile__menu__item">
          <a href="" class="exp-navbar-mobile__menu__link">
            à propos
          </a>
        </li>
        <li class="exp-navbar-mobile__menu__item" @click.prevent.stop="popupTrigger">
          <button class="exp-navbar-mobile__menu__link">
            Contact
          </button>
        </li>
        <li class="exp-navbar-mobile__menu__item">
          <a href="https://isuiteexpansae.coaxis.com/isuiteexpert/?CNX=CNX97&CAB=0000" target="_blank" rel="noopener" class="exp-navbar-mobile__menu__link">
            Connexion
          </a>
        </li>
        <li class="exp-navbar-mobile__menu__item">
          <a href="tel:0688859296">
            <ExpBtn type="white" :icon='true' style="margin-left: 40px">
              <Calculator class="exp-btn-icon"/>
              06 88 85 92 96
            </ExpBtn>
          </a>
        </li>
      </ul>
    </div>
</template>

<style lang="scss">
  .exp-navbar {
    padding: 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 10;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @media (min-width: $breakpoint-large) {
      padding: 30px 0;
    }
    &__logo {
      @media (max-width: $breakpoint-large - 1) {
        display: block;
        text-align: center;
        float: none;
      }
      margin-top: 7px;
      height: 40px;
      display: inline-block;
      float: left;
      &__link {
        height: 35px;
        display: block;
        @media (min-width: $breakpoint-large) {
          height: 40px;
        }
      }
    }

    &__menu {
      list-style: none;
      margin: 0;
      display: block;
      padding: 0;
      float: right;
      display: none;
      @media (min-width: $breakpoint-large) {
        display: inline-block;
      }
      &__item {
        display: inline-block;
        letter-spacing: 1.25px;
        font-size: 16px;
      }
      &__link {
        text-transform: uppercase;
        text-decoration: none;
        color: $text-color;
        letter-spacing: 1.25px;
        margin: 0 30px;
        line-height: 40px;
        position: relative;
        display: block;
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: white;
        &:after {
          content: "";
          height: 4px;
          background-color: $yellow;
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          opacity: 0;
          transition: opacity 0.3s;
          border-radius: 2px;
        }
        &:hover,
        &:focus,
        &.active-link {
          background-color: white;
          color: $text-color;
          outline: none;
          &:after {
            opacity: 1;
            transition: opacity 0.3s;
          }
        }
      }
    }
  }

  [class^="exp-navbar-mobile"],
  [class*="exp-navbar-mobile"] {
    @media (min-width: $breakpoint-large) {
      display: none;
    }
  }
  .exp-navbar-mobile {
    &__open,
    &__close {
      cursor: pointer;
      border: 2px solid transparent;
      border-radius: 4px;
      transition: border-color 0.3s;
      &:hover,
      &:focus {
        border-color: $text-color;
        transition: border-color 0.3s;
      }
    }
    &__open {
      height: 40px;
      position: absolute;
      top: 0;
      left: 15px;
      fill: lighten($blue, 10);
    }
    &__close {
      height: 35px;
      top: 20px;
      left: 20px;
      padding: 5px;
      margin-left: 20px;
      margin-bottom: 10px;
      fill: white;
    }
    &__menu {
      position: absolute;
      top: 0;
      left: -100%;
      background: linear-gradient(90deg, darken(#7592cc, 20) 0%, rgba(161, 184, 230, 1) 100%);
      width: 100%;
      bottom: 0;
      display: inline;
      list-style: none;
      padding: 25px 0;
      transition: left 0.7s ease-in-out;
      height: 100vh;
      z-index: 100;
      &.menuOpen {
        left: 0;
      }
      @media (min-width: $breakpoint-large) {
        display: none;
      }
      &__item {
        text-transform: uppercase;
        display: block;
        border-top: 1px solid rgba(161, 184, 230, 1);
        &:last-of-type {
          border-bottom: none;
          padding-top: 12px;
        }
      }
      &__link {
        font-family: "Montserrat";
        padding: 25px 40px;
        border: none;
        color: white;
        transition: all 0.3s;
        width: 100%;
        text-decoration: none;
        width: 100%;
        display: block;
        text-transform: uppercase;
        cursor: pointer;
        background-color: transparent;
        font-size: 24px;
        text-align: left;
        &:hover,
        &:focus {
          background-color: darken(#7592cc, 20);
          color: white;
          transition: all 0.3s;
          outline: none;
        }
      }
    }
  }
</style>

<script>
import Calculator from '../assets/svg/calculator.vue';
import Close from '../assets/svg/close.vue';
import Container from '../components/layout/Container.vue';
import ExpBtn from '../components/Button';
import Logo from '../assets/svg/logo.vue';
import MobileMenu from '../assets/svg/mobileMenu.vue';
import PopupContact from '../pages/PopupContact.vue';

export default {
  name: 'Navbar',

  components: {
    Calculator,
    Close,
    Container,
    ExpBtn,
    Logo,
    MobileMenu,
    PopupContact,
  },

  data() {
    return {
      mobileMenuOpen: false,
      popupOpen: false,
      landingActive: false,
      contactActive: false,
    };
  },

  computed: {
    mobileMenuClass: function(){
      return ['exp-navbar-mobile__menu', this.mobileMenuOpen ? 'menuOpen': ''];
    }
  },

  watch: {
    popupOpen() {
      this.contactActive = false;
      this.landingActive = false;
      if (this.popupOpen) {
        this.contactActive = true;
      } else if (this.$route.name === 'landing') {
        this.landingActive = true;
      }
    },
  },

  methods: {
    openMobileMenu() {
      this.mobileMenuOpen = true;
      document.getElementsByTagName('body')[0].classList.add('exp-overflow-hidden');
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
      document.getElementsByTagName('body')[0].classList.remove('exp-overflow-hidden');
    },
    popupTrigger() {
      this.popupOpen = !this.popupOpen;
      this.closeMobileMenu();
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$route.name === 'landing') {
        this.landingActive = true;
      } else {
        this.landingActive = false;
      }
    });
  },
}
</script>
